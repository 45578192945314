<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Engineering - {{ serviceProjectResponse.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
            >                    
                <JobInformation 
                    :id="serviceProjectResponse.idService"
                    :project="projectSelected"
                    :showProjects="false"
                    :showJobSummary="true"
                    :showButtonsCreateEditProject="false"
                    :showButtonsCreateEditVersion="false"
                    :showClassification="true"
                    :showDisabledClassification="true"
                />
            </v-col>
        </v-row>

        <v-row>

            <v-col
                cols="12"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Engineering Signature
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <canvas id="canvas" hidden></canvas>
                                    <label>Pool Plan Signed by Engineer</label>
                                    <v-file-input
                                        v-model="poolPlanListFiles" 
                                        accept=".pdf"
                                        small-chips
                                        multiple
                                        outlined
                                        placeholder="Click here to add"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        @change="addFilesToList(poolPlanListFiles, poolPlanFiles)"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    v-for="(itemFile, index) in poolPlanFiles.filter(item => item.deleted == false)"
                                    :key="index"
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-card 
                                        v-if="itemFile.id != 0"
                                        max-width="200"
                                    >
                                        <v-card
                                            class="cardDownload"
                                            @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                        >
                                            <v-card-text>
                                                <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                <span class="imageFileName">{{ itemFile.fileName }}</span>
                                            </v-card-text>
                                        </v-card>
                                        <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(poolPlanFiles, itemFile)">mdi mdi-delete</v-icon>
                                    </v-card>
                                </v-col>
                            </v-row>
                            
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <canvas id="canvas" hidden></canvas>
                                    <label>Load Calculation Signed by Engineer</label>
                                    <v-file-input
                                        v-model="loadCalculationListFiles" 
                                        accept=".pdf"
                                        small-chips
                                        multiple
                                        outlined
                                        placeholder="Click here to add"
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                        @change="addFilesToList(loadCalculationListFiles, loadCalculationFiles)"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    v-for="(itemFile, index) in loadCalculationFiles.filter(item => item.deleted == false)"
                                    :key="index"
                                    cols="12"
                                    lg="1"
                                    md="4"
                                    sm="12"
                                >
                                    <v-card 
                                        v-if="itemFile.id != 0"
                                        max-width="200"
                                    >
                                        <v-card
                                            class="cardDownload"
                                            @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                        >
                                            <v-card-text>
                                                <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                <span class="imageFileName">{{ itemFile.fileName }}</span>
                                            </v-card-text>
                                        </v-card>
                                        <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(loadCalculationFiles, itemFile)">mdi mdi-delete</v-icon>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <label>Notes</label>
                                    <v-textarea
                                        v-model="serviceDraftingUploadFilesRequest.engineeringNotes"
                                        rows="4"
                                        row-height="23"
                                        auto-grow
                                        outlined
                                    >
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <div
            class="text-center"
        >
            <v-row>
                <v-col 
                    cols="7"
                    lg="7"
                    md="7"
                    sm="8"
                >
                    <v-row>
                        <v-col 
                            style="text-align: right"
                            cols="6"
                            lg="8"
                            md="7"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2"
                                        v-on="on"
                                        style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                        @click="cancel()"
                                    >
                                        Back
                                    </v-btn>
                                </template>
                                <span>Back</span>
                            </v-tooltip>
                        </v-col>
                        <v-col 
                            style="text-align: center"
                            cols="6"
                            lg="4"
                            md="5"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        v-on="on"
                                        style="background-color: var(--color__red) !important; color: #ffffff !important"
                                        @click="validateAndContinue('DENY')"
                                    >
                                        Deny
                                    </v-btn>
                                </template>
                                <span>Deny</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col 
                    cols="5"
                    lg="5"
                    md="5"
                    sm="4"
                >
                    <v-row>
                        <v-col 
                            style="text-align: left"
                            cols="12"
                            lg="6"
                            md="6"
                            sm="6"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        class="mx-2"
                                        v-on="on"
                                        @click="validateAndContinue('APPROVE')"
                                    >
                                        Approve
                                    </v-btn>
                                </template>
                                <span>Approve</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>
        </div>


        <v-dialog
            v-model="showDialogMoveBack"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    To which stage would you like to send the job back to?
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <v-btn 
                                        class="mx-2 buttonMoveBack"
                                        outlined
                                        @click="setStatusAndSave(PROJECT_STATUS.SALES_IN_PROGRESS)"
                                    >
                                        Sales (addendums needed)
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >

                                    <v-btn
                                        class="mx-2 buttonMoveBack"
                                        outlined
                                        @click="setStatusAndSave(PROJECT_STATUS.DRAFTING_NEEDS_APPROVAL)"
                                    >
                                        Drafting (major changes, approvals needed)
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <v-btn
                                        class="mx-2 buttonMoveBack"
                                        outlined
                                        @click="setStatusAndSave(PROJECT_STATUS.DRAFTING)"
                                    >
                                        Drafting (minor changes, no approvals needed)
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <v-btn
                                        style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                        class="mx-2 buttonMoveBack"
                                        outlined
                                        @click="showDialogMoveBack = false"
                                    >
                                        Cancel
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>
            </v-card>
        </v-dialog>


        <ActionDialog 
            v-on:methodCancelOrDenyToCall="dialog.methodCancelOrDeny"
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :button1Text="dialog.button1Text"
            :button2Text="dialog.button2Text"
            :dismissDialog="dialog.dismissDialog"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import JobInformation from "@/components/Services/JobInformation";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT, 
        URL_ATTACHMENT, 
        PROJECT_STATUS
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            JobInformation
        },

        mixins: [Helpers],

        data: () => ({

            URL_ATTACHMENT,

            loading: false,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            projectSelected: null,

            serviceDraftingUploadFilesRequest: {
                id: 0,
                idService: 0,
                poolPlanNotes: "",
            },

            poolPlanListFiles: [],
            poolPlanFiles: [],

            loadCalculationListFiles: [],
            loadCalculationFiles: [],

            listFileDelete: [],
            itemFileToDelete: {},

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                button1Text: "Cancel",
                button2Text: "Confirm",
                methodCancelOrDeny: ( () => {} ),
                methodConfirm: ( () => {} ),
                dismissDialog: true,
                params: null
            },

            showDialogMoveBack: false,

            status: 0,
            type: '',

            PROJECT_STATUS
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            addFilesToList(listFiles, files) {

                if (listFiles) {

                    listFiles.forEach(itemFile => {

						if (itemFile) {

                            let fileName = itemFile.name;

                            if (itemFile.name && itemFile.name.length > 15) {
                                fileName = itemFile.name.toString().substring(0, 12) + "..."
                            }

                            let item = {
                                id: 0,
                                file: itemFile,
                                fileName: fileName,
                                deleted: false,
                            }

                            files.push(item);
						}	
					});
                }
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    // let response = await this.$store.dispatch("serviceModule/GetById", this.id);
                    let response = await this.$store.dispatch("serviceModule/GetServiceProjectById", this.id);

                    this.serviceProjectResponse = response.result;

                    this.projectSelected = {
                        id: this.serviceProjectResponse.id,
                        idService: this.serviceProjectResponse.idService,
                        classification: this.serviceProjectResponse.classification,
                        name: this.serviceProjectResponse.name,
                        step: this.serviceProjectResponse.step,
                        version: this.serviceProjectResponse.version,
                        versionName: this.serviceProjectResponse.versionName,
                        totalProject: this.serviceProjectResponse.totalProject
                    }
                    
                    let responseDraftingUploadFiles = await this.$store.dispatch("serviceModule/GetDraftingUploadFilesById", this.id);

                    if (responseDraftingUploadFiles.result.id != 0) {
                        this.serviceDraftingUploadFilesRequest = responseDraftingUploadFiles.result;

                        this.poolPlanListFiles = [];
                        this.poolPlanFiles = [];

                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.DRAFTING.value, idKey: this.serviceDraftingUploadFilesRequest.id }); 

                        let poolPlanListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_POOL_PLAN_ENGINEER_SIGNED.value);
                        if (poolPlanListFiles != null && poolPlanListFiles != undefined) {

                            poolPlanListFiles.forEach(itemFile => {

                                this.poolPlanFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileName,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }

                        this.loadCalculationListFiles = [];
                        this.loadCalculationFiles = [];

                        let loadCalculationListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_LOAD_CALCULATION_SIGNED.value);
                        if (loadCalculationListFiles != null && loadCalculationListFiles != undefined) {

                            loadCalculationListFiles.forEach(itemFile => {

                                this.loadCalculationFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileName,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/drafting/pipeline" });
            },
            
            async confirmFileDelete(listFile, itemFileToDelete) {

                this.listFileDelete = listFile;
                this.itemFileToDelete = itemFileToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this file [${itemFileToDelete.fileName}], confirm your decision?`,
                    methodCancelOrDeny: () => {},
                    methodConfirm: this.deleteFile,
                    dismissDialog: true,
                    params: 0
                };
            },

            async deleteFile() {

                this.itemFileToDelete.deleted = true;
            },       

            async uploadFiles(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async saveFiles() {

                let allResult = [];

                this.poolPlanFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.serviceDraftingUploadFilesRequest.id, itemFile.file, TYPE_DOCUMENT.DRAFTING_POOL_PLAN_ENGINEER_SIGNED.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.DRAFTING_POOL_PLAN_ENGINEER_SIGNED.description,
                            message: result.message
                        })
                    }
                })

                this.loadCalculationFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.serviceDraftingUploadFilesRequest.id, itemFile.file, TYPE_DOCUMENT.DRAFTING_LOAD_CALCULATION_SIGNED.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.DRAFTING_LOAD_CALCULATION_SIGNED.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `The [${itemResult.typeDocument}] reported the error: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async validateCheckItems(type) {

                let result = {
                    success: true
                };

                if (type == 'APPROVE') {

                    if (this.poolPlanFiles == null || this.poolPlanFiles == undefined || this.poolPlanFiles.length <= 0) {
                        result.success = false;
                        this.showToast("error", "Warning!", `You must add Pool Plan File Signed by Engineer.`);
                    }

                    if (this.loadCalculationFiles == null || this.loadCalculationFiles == undefined || this.loadCalculationFiles.length <= 0) {
                        result.success = false;
                        this.showToast("error", "Warning!", `You must add Load Calculation File Signed by Engineer.`);
                    }
                }
                else {
                    if (this.serviceDraftingUploadFilesRequest.engineeringNotes == '' || this.serviceDraftingUploadFilesRequest.engineeringNotes == null || this.serviceDraftingUploadFilesRequest.engineeringNotes == undefined) {
                        result.success = false;
                        this.showToast("error", "Warning!", `You must provide a reason for denial.`);
                    }
                }

                return result;
            },

            async validateAndContinue(type) {

                let result = await this.validateCheckItems(type);

                this.type = type;

                if (result.success)
                {
                    if (type == 'APPROVE') {
                        this.status = this.PROJECT_STATUS.PERMITTING_PLAN_APPROVAL_PENDING;
                        this.dialog = {
                            show: true,
                            headerText: 'Confirmation',
                            bodyText: "You are finishing ENGINEERING STEP and go to the PERMITTING DEPARTMENT, confirm your decision?",
                            methodCancelOrDeny: () => {},
                            methodConfirm: this.save,
                            dismissDialog: true,
                            params: 0
                        };
                    }
                    else {
                        this.dialog = {
                            show: true,
                            headerText: 'Confirmation',
                            bodyText: "You are denying POOL PLAN, confirm your decision?",
                            methodCancelOrDeny: () => {},
                            methodConfirm: this.showMoveBack,
                            dismissDialog: false,
                            params: 0
                        };
                    }
                }
            },

            async showMoveBack() {

                this.showDialogMoveBack = true;
            },

            async setStatusAndSave(status) {

                this.status = status;
                await this.save();
            },

            async save() {

                this.showLoading();

                this.serviceDraftingUploadFilesRequest.idService = this.id;

                this.serviceDraftingUploadFilesRequest.financialImpact = 0;

                if (this.status == this.PROJECT_STATUS.SALES_IN_PROGRESS) {
                    this.serviceDraftingUploadFilesRequest.financialImpact = 1;
                }

                const result = await this.$store.dispatch("serviceModule/CreateUpdateDraftingUploadFiles", this.serviceDraftingUploadFilesRequest);

                if (result.success === true) {

                    this.serviceDraftingUploadFilesRequest.id = result.id;

                    if (await this.saveFiles()) {

                        this.showToast("success", "Success!", result.message);

                        let updateStatusRequest = {
                            id: this.id,
                            status: this.status
                        };

                        await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);

                        this.cancel();
                    }
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }

                this.hideLoading();
            },
        },

        async created() {

            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }
    
    .v-file-input > .v-input__control {
        height: 55px !important;
    }

    .cardDownload {
        text-align: center;
    }

    .buttonMoveBack {
        width: 100%;
    }

</style>